import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { duration, stagger } from '../../utils/transitions';
import { easeInOutSine } from '../../utils/easings';

const Mask = styled.span`
  display: block;
  overflow: hidden;
`;

const Inner = styled.span`
  display: block;
  transition: transform ${duration}s ${easeInOutSine}
    ${({ delay }) => delay * stagger}s;
  transform: ${({ active }) => `translateY(${active ? 0 : 100}%)`};
`;

export default function MaskAnimation({
  active,
  className,
  delay = 0,
  children,
  onTransitionEnd,
}) {
  return (
    <Mask className={className}>
      <Inner
        active={active}
        delay={delay}
        onTransitionEnd={() => onTransitionEnd && onTransitionEnd()}
      >
        {children}
      </Inner>
    </Mask>
  );
}

MaskAnimation.propTypes = {
  active: PropTypes.bool,
  onTransitionEnd: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
};
