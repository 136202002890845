/* eslint-disable no-return-assign */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import TransitionLink from 'gatsby-plugin-transition-link';

import { gsap, Power1 } from 'gsap/dist/gsap';

import { store } from '../../context/store';
import { duration } from '../../utils/transitions';

function PageTransitionLink({
  background,
  children,
  theme,
  animate,
  ...props
}) {
  const { dispatch, state } = useContext(store);
  const { pageTransitionMaskRef: mask } = state;
  const desktop = ({ node, props: { length: seconds } }) =>
    gsap
      .timeline()
      .call(() => {
        dispatch({ type: 'pageTransitionStarted' });
        dispatch({
          type: 'pageTransitionMaskBackground',
          payload: background || theme.colors.black,
        });
      })
      .set(mask, { y: 0, x: '100%', opacity: 1 })
      .to(mask, seconds, {
        x: '0%',
        ease: Power1.easeOut,
      })
      .call(() => dispatch({ type: 'pageTransitionEnded' }))
      .set(node, { opacity: 0 })
      .set(mask, { x: '-100%' });

  const mobile = ({ node }) => {
    dispatch({ type: 'pageTransitionStarted' });
    return gsap
      .timeline()
      .set(node, { opacity: 0 })
      .call(() => dispatch({ type: 'pageTransitionEnded' }));
  };

  const length = animate ? duration * 0.75 : 0;
  return (
    <TransitionLink
      exit={{
        length,
        trigger: ({ exit, node }) =>
          animate
            ? desktop({ props: exit, node })
            : mobile({ props: exit, node }),
      }}
      entry={{
        delay: length,
      }}
      rel="dofollow"
      {...props}
    >
      {children}
    </TransitionLink>
  );
}

export default withTheme(PageTransitionLink);

PageTransitionLink.propTypes = {
  animate: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};
