module.exports = {
  colors: {
    black: '#000',
    blueGrey: '#1D2F3E',
    cream: '#fff3e9',
    darkBlue: '#0f1f2d',
    darkGreen: '#183331',
    greyDark: '#4F4F4F',
    greyLight: '#BDBDBD',
    lightBlue: '#77828b',
    lightGreen: '#e7edeb',
    navy: '#00294B',
    offWhite: '#eff2ff',
    white: '#FFF',
    darkMaroon: '#512043',
    lightMaroon: '#ede7ed',
  },
  breakpoints: {
    mobileSmall: 320,
    mobile: 400,
    footerTablet: 550,
    contactTablet: 550,
    tabletPortrait: 800,
    mobileNavigation: 900,
    footerDesktop: 900,
    contactDesktop: 950,
    tabletLandscape: 1100,
    laptop: 1400,
    desktop: 1920,
    minHeroHeight: 500,
  },
};
