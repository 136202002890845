import styled from 'styled-components';

import fluidRange from '../../utils/fluidRange';
import { minWidth, maxWidth } from '../../utils/breakpoints';

export default styled.section`
  display: grid;
  grid-column-gap: 2.5vw;
  grid-template-columns:
    [full-bleed-start] minmax(var(--gutter), 1fr) [main-start] repeat(
      12,
      [col] minmax(0, 100px)
    )
    [main-end] minmax(var(--gutter), 1fr) [full-bleed-end];
  ${maxWidth('tabletLandscape')} {
    --gutter: 2.5%;
  }
  ${minWidth('tabletLandscape')} {
    --gutter: 5%;
  }
  ${fluidRange({
    prop: 'grid-row-gap',
    fromSize: '25px',
    toSize: '50px',
  })}}
  &:first-child {
    ${({ paddedTop }) =>
      paddedTop &&
      fluidRange({
        prop: 'padding-top',
        fromSize: '80px',
        toSize: '200px',
      })}
  }
  &:not(:first-child) {
    ${({ paddedTop }) =>
      paddedTop &&
      fluidRange({
        prop: 'padding-top',
        fromSize: '40px',
        toSize: '200px',
      })}
  }
  ${({ paddedBottom }) =>
    paddedBottom &&
    fluidRange({
      prop: 'padding-bottom',
      fromSize: '40px',
      toSize: '200px',
    })}
`;
