import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 24px;
  display: grid;
  grid-gap: 4px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: currentColor;
  display: block;
  transition: transform ${({ animationSpeed }) => animationSpeed}s ease-in-out,
    opacity ${({ animationSpeed }) => animationSpeed}s ease;

  ${({ active }) =>
    active &&
    css`
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }
    `}
`;

export default function Hamburger({ className, active, animationSpeed }) {
  return (
    <Wrapper className={className}>
      <Line active={active} animationSpeed={animationSpeed} />
      <Line active={active} animationSpeed={animationSpeed} />
      <Line active={active} animationSpeed={animationSpeed} />
    </Wrapper>
  );
}

Hamburger.propTypes = {
  active: PropTypes.bool.isRequired,
  animationSpeed: PropTypes.number.isRequired,
  className: PropTypes.string,
};
