import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PageTransitionLink from './PageTransitionLink';

import LocaleContext from '../../context/LocaleContext';

export default function IntLink({ animate = true, to, children, ...props }) {
  const { locale } = useContext(LocaleContext);
  return (
    <PageTransitionLink
      to={locale.isDefault ? to : `/${locale.id}${to}`}
      animate={animate}
      {...props}
    >
      {children}
    </PageTransitionLink>
  );
}

IntLink.propTypes = {
  animate: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
