import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Grid from './Grid';
import UnstyledButton from './UnstyledButton';
import LocaleSwitcher from './LocaleSwitcher';
import IntLink from './IntLink';
import Hamburger from './Hamburger';
import MaskAnimation from './MaskAnimation';
import { HeaderText, H6 } from './Typography';

import useBreakpoint from '../../hooks/useBreakpoint';
import { minWidth, maxWidth } from '../../utils/breakpoints';
import fluidRange from '../../utils/fluidRange';
import { duration } from '../../utils/transitions';
import { store } from '../../context/store';

const SOCIAL_MEDIA = [
  { name: 'Instagram', url: 'https://www.instagram.com/edge_dimension/' },
  { name: 'Facebook', url: 'https://www.facebook.com/EdgeDimension/' },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/company/edge-dimension' },
];

const Wrapper = styled(Grid)`
  top: 0;
  position: absolute;
  z-index: 999999;
  width: 100%;
  /* background: none !important; */
  ${maxWidth('mobileNavigation')} {
    color: ${({ mobileMenuActive, theme, color }) =>
      mobileMenuActive ? theme.colors.white : color};
    background: ${({ theme, mobileMenuActive }) =>
      mobileMenuActive ? theme.colors.black : 'transparent'};
    transition: background ${({ animationSpeed }) => animationSpeed}s ease
        ${({ animationSpeed, mobileMenuActive }) =>
          mobileMenuActive ? 0 : animationSpeed * 1.5}s,
      color ${({ animationSpeed }) => animationSpeed}s ease
        ${({ mobileMenuActive }) => (mobileMenuActive ? 0 : duration / 2)}s;
    pointer-events: ${({ mobileMenuActive }) =>
      mobileMenuActive ? 'initial' : 'none'};
    height: 100%;
    grid-template-rows:
      60px /* Header  */
      5vh /* Space  */
      auto /* Nav  */
      auto /* Locales  */
      1fr /* Space  */
      auto /* Social  */
      2.5%; /* Space  */
    @media (orientation: landscape) {
      grid-template-rows: 60px 0px auto 1fr 1fr;
    }
  }
  ${minWidth('mobileNavigation')} {
    color: ${({ color }) => color};
    transition: color ${duration * 0.75}s ease;
    align-items: center;
    justify-content: space-between;
  }
  ${fluidRange(
    {
      prop: 'height',
      fromSize: '90px',
      toSize: '120px',
    },
    'mobileNavigation',
    'desktop'
  )};
`;

const Logo = styled(IntLink)`
  ${fluidRange({
    prop: 'font-size',
    fromSize: '25px',
    toSize: '28px',
  })};
  user-select: none;
  line-height: 1.2em;
  font-family: Dharma Gothic;
  text-transform: uppercase;
  justify-self: flex-start;
  ${maxWidth('mobileNavigation')} {
    align-self: center;
    grid-column: col 1 / span 8;
    grid-row: 1;
    justify-self: flex-start;
    pointer-events: initial;
  }
  ${minWidth('mobileNavigation')} {
    grid-column: col 1 / span 4;
  }
`;

const MenuToggle = styled(UnstyledButton)`
  right: -8px;
  position: relative;
  ${maxWidth('mobileNavigation')} {
    outline: none;
    height: 42px;
    width: 42px;
    font-size: 0;
    justify-self: flex-end;
    grid-column: col 11 / span 2;
    grid-row: 1;
    align-self: center;
    pointer-events: initial;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${minWidth('mobileNavigation')} {
    display: none;
  }
`;

const Nav = styled(HeaderText)`
  ${maxWidth('mobileNavigation')} {
    grid-column: col / span 12;
    grid-row: 3;
    display: flex;
    flex-direction: column;
    line-height: 1.4em;
    @media (orientation: landscape) {
      font-size: 30px;
    }
  }
  ${minWidth('mobileNavigation')} {
    line-height: 1.2em;
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 3.5vw;
    grid-column: col 5 / span 5;
    justify-self: flex-start;
    white-space: nowrap;
  }
`;

const NavLink = styled(IntLink)`
  opacity: ${({ active }) => (active ? 0.55 : 1)};
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.55;
  }
`;

const Locales = styled(HeaderText)`
  line-height: 1.2em;
  ${maxWidth('mobileNavigation')} {
    grid-row: 4;
    grid-column: col / span 12;
    @media (orientation: landscape) {
      font-size: 30px;
    }
  }
  ${minWidth('mobileNavigation')} {
    grid-column: col 11 / span 2;
    text-align: right;
    justify-self: flex-end;
  }
`;

const SocialMedia = styled.nav`
  ${maxWidth('mobileNavigation')} {
    display: flex;
    flex-direction: column;
    grid-row: 6;
    grid-column: col / span 12;
    @media (orientation: landscape) {
      display: none;
    }
  }
  ${minWidth('mobileNavigation')} {
    display: none;
  }
`;

export default function Header({
  className,
  activePath,
  siteTitle,
  routes,
  color,
}) {
  const { heroAnimated } = useContext(store).state;
  const isDesktop = useBreakpoint('mobileNavigation');
  const animationSpeed = duration / 2;
  const $wrapper = useRef(null);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [intro, setIntro] = useState(false);
  const isHome = activePath === '/';

  useEffect(() => {
    if (heroAnimated && isHome) {
      setIntro(true);
    } else if (!isHome) {
      setIntro(true);
    }
  }, [heroAnimated, isHome]);

  if (mobileMenuActive) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    disableBodyScroll($wrapper);
  } else {
    enableBodyScroll($wrapper);
  }
  return (
    <Wrapper
      color={color}
      as="header"
      className={className}
      ref={$wrapper}
      mobileMenuActive={mobileMenuActive}
      animationSpeed={animationSpeed}
    >
      <Logo
        animate={!mobileMenuActive}
        to="/"
        onClick={() => setMobileMenuActive(false)}
      >
        <MaskAnimation active={intro}>{siteTitle}</MaskAnimation>
      </Logo>
      <MenuToggle onClick={() => setMobileMenuActive(!mobileMenuActive)}>
        {mobileMenuActive ? 'Close mobile menu' : 'Open mobile menu'}
        <MaskAnimation active={intro} delay={mobileMenuActive ? 1 : 0}>
          <Hamburger
            active={mobileMenuActive}
            animationSpeed={animationSpeed}
          />
        </MaskAnimation>
      </MenuToggle>
      <Nav
        as="nav"
        mobileMenuActive={mobileMenuActive}
        animationSpeed={animationSpeed}
      >
        {routes.map((route, i) => (
          <NavLink
            animate={!!isDesktop}
            active={activePath.includes(route.path) ? 'true' : null}
            background={route.background}
            key={route.path}
            to={route.path}
            onClick={() => setMobileMenuActive(false)}
          >
            <MaskAnimation
              active={isDesktop ? intro : mobileMenuActive}
              delay={mobileMenuActive ? i : 0}
            >
              {route.name}
            </MaskAnimation>
          </NavLink>
        ))}
      </Nav>
      <Locales mobileMenuActive={mobileMenuActive} as="nav">
        <MaskAnimation
          active={isDesktop ? intro : mobileMenuActive}
          delay={mobileMenuActive ? 3 : 0}
        >
          <LocaleSwitcher
            activePath={activePath}
            animationSpeed={animationSpeed}
            onClick={() => setMobileMenuActive(false)}
          />
        </MaskAnimation>
      </Locales>
      <SocialMedia
        mobileMenuActive={mobileMenuActive}
        animationSpeed={animationSpeed}
      >
        {SOCIAL_MEDIA.map((lifeFuck, i) => (
          <MaskAnimation
            key={lifeFuck.url}
            active={mobileMenuActive}
            delay={mobileMenuActive ? i : 0}
          >
            <H6 as="a" href={lifeFuck.url}>
              {lifeFuck.name}
            </H6>
          </MaskAnimation>
        ))}
      </SocialMedia>
    </Wrapper>
  );
}

Header.propTypes = {
  activePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  siteTitle: PropTypes.string.isRequired,
  color: PropTypes.string,
  routes: PropTypes.array.isRequired,
};
