import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import PageTransitionMask from './PageTransitionMask';
import LocaleContext from '../../context/LocaleContext';

import theme from '../../utils/theme';
import makeRoutes from '../../utils/makeRoutes';
import { getPageHeaderColor } from '../../utils/colors';

import { StateProvider } from '../../context/store';

export default function Layout({ children, pageContext, data, pageResources }) {
  if (
    process.env.NODE_ENV === 'development' &&
    pageResources.page.path === '/dev-404-page/'
  )
    return children;
  const { locale, id, unlocalizedPath } = pageContext;
  return (
    <StateProvider>
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            contact: sanityContact {
              email
              officeAddress
              phoneNumber
            }
            sanitySettings {
              ...menu
            }
          }
        `}
        render={(settings) => {
          const navRoutes = makeRoutes(
            settings.sanitySettings.menuItems,
            locale.id
          );
          return (
            <ThemeProvider theme={theme}>
              <LocaleContext.Provider value={{ locale }}>
                <Header
                  activePath={unlocalizedPath}
                  color={getPageHeaderColor(id, data)}
                  routes={navRoutes.filter((route) => route.path !== '/')}
                  siteTitle={settings.site.siteMetadata.title}
                />
                <main>{children}</main>
                <Footer
                  routes={navRoutes}
                  siteTitle={settings.site.siteMetadata.title}
                  email={settings.contact.email}
                  address={settings.contact.officeAddress}
                  phoneNumber={settings.contact.phoneNumber}
                />
                <PageTransitionMask />
              </LocaleContext.Provider>
            </ThemeProvider>
          );
        }}
      />
    </StateProvider>
  );
}

Layout.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object.isRequired,
  pageResources: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};
