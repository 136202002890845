import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from './Grid';
import IntLink from './IntLink';
import { P } from './Typography';

import { minWidth, maxWidth, betweenWidth } from '../../utils/breakpoints';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  line-height: 1.333333333em;
  padding: 4vw 0;
`;

const Nav = styled(P)`
  display: flex;
  flex-direction: column;
  ${maxWidth('footerTablet')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('footerTablet', 'footerDesktop')} {
    grid-column: col / span 6;
  }
  ${minWidth('footerDesktop')} {
    grid-column: col / span 2;
  }
`;

const Address = styled(P)`
  ${maxWidth('footerTablet')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('footerTablet', 'footerDesktop')} {
    grid-column: span 6;
  }
  ${minWidth('footerDesktop')} {
    grid-column: span 3;
  }
`;

const Contact = styled(P)`
  display: flex;
  flex-direction: column;
  ${maxWidth('footerTablet')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('footerTablet', 'footerDesktop')} {
    grid-column: col 7 / span 6;
  }
  ${minWidth('footerDesktop')} {
    grid-column: span 3;
  }
`;

const Copyright = styled(P)`
  ${maxWidth('footerTablet')} {
    grid-column: col / span 12;
  }
  ${betweenWidth('footerTablet', 'footerDesktop')} {
    grid-column: col 1 / span 6;
    grid-row: 2;
    margin-top: 24px;
  }
  ${minWidth('footerDesktop')} {
    text-align: right;
    grid-column: span 4;
  }
`;

export default function Footer({
  email,
  phoneNumber,
  address,
  siteTitle,
  routes,
}) {
  return (
    <Wrapper as="footer">
      <Nav as="nav">
        {routes.map((route) => (
          <IntLink
            key={route.path}
            to={route.path}
            background={route.background}
          >
            {route.name}
          </IntLink>
        ))}
      </Nav>
      <Address as="address">{address}</Address>
      <Contact>
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        <a href={`mailto:${email}`}>{email}</a>
      </Contact>
      <Copyright>
        &copy;&nbsp;
        {new Date().getFullYear()}
        &nbsp;
        {siteTitle}
      </Copyright>
    </Wrapper>
  );
}

Footer.propTypes = {
  email: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  siteTitle: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
};
