import styled from 'styled-components';

import fluidRange from '../../utils/fluidRange';

export const HomeHero = styled.h1`
  font-family: Dharma Gothic;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  line-height: 0.86em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '75px',
    toSize: '275px',
  })};
`;

export const H1 = styled.h1`
  font-family: Dharma Gothic;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  line-height: 0.86em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '85px',
    toSize: '205px',
  })};
`;

export const H2 = styled.h2`
  font-family: Dharma Gothic;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  line-height: 0.86em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '80px',
    toSize: '170px',
  })};
`;

export const H3 = styled.h2`
  font-weight: normal;
  margin: 0;
  line-height: 1.233333333em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '27px',
    toSize: '74px',
  })};
`;

export const H4 = styled.h4`
  font-weight: normal;
  margin: 0;
  line-height: 1.233333333em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '24px',
    toSize: '50px',
  })};
`;

export const H5 = styled.h5`
  font-weight: normal;
  margin: 0;
  line-height: 1.233333333em;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '20px',
    toSize: '35px',
  })};
`;

export const H6 = styled.h6`
  font-weight: normal;
  font-family: Roboto Mono;
  line-height: 2em;
  margin: 0;
  ${fluidRange({
    prop: 'font-size',
    fromSize: '14px',
    toSize: '16px',
  })};
`;

export const P = styled.p`
  ${fluidRange({
    prop: 'font-size',
    fromSize: '18px',
    toSize: '20px',
  })};
  line-height: 1.5em;
  margin: 0;
`;

export const HeaderText = styled.div`
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '42px',
      toSize: '60px',
    },
    'mobileSmall',
    'tabletPortrait'
  )};
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '20px',
      toSize: '22px',
    },
    'tabletPortrait',
    'desktop'
  )};
`;
