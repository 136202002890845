import { colors } from './theme';

function getSwipeBackgroundColor(id) {
  if (id === 'about') return colors.offWhite;
  if (id === 'contact') return colors.cream;
  if (id === 'services') return colors.lightGreen;
  if (id === 'galleryPage') return colors.lightMaroon;
  return colors.black;
}

function getPath(id) {
  if (id === 'home') return '/';
  if (id === 'galleryPage') return '/gallery';
  return `/${id}/`;
}

export default function makeRoutes(pages, locale) {
  const routes = pages
    .filter((page) => page.title !== undefined)
    .map((page) => ({
      name: page.title[locale],
      path: getPath(page._id),
      background: getSwipeBackgroundColor(page._id),
    }));
  return routes;
}
