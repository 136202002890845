import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import locales from '../../utils/locales';

import LocaleContext from '../../context/LocaleContext';

const Wrapper = styled.nav`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 1vw;
`;

const Locale = styled(Link)`
  text-transform: capitalize;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.55;
  }
`;

export default function LocaleSwitcher({ activePath, className, onClick }) {
  const { locale: currentLocale } = useContext(LocaleContext);
  return (
    <Wrapper className={className}>
      {locales.map((locale) =>
        locale.id !== currentLocale.id ? (
          <Locale
            onClick={onClick}
            key={locale.id}
            hrefLang={locale.id}
            title={locale.title}
            to={locale.isDefault ? activePath : `/${locale.id}${activePath}`}
          >
            {locale.id}
          </Locale>
        ) : null
      )}
    </Wrapper>
  );
}

LocaleSwitcher.propTypes = {
  activePath: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
