// store.js
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  pageTransitioned: true,
  heroAnimated: false,
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'heroAnimated':
        return { ...prevState, heroAnimated: true };
      case 'pageTransitionStarted':
        return { ...prevState, pageTransitioned: false };
      case 'pageTransitionEnded':
        return { ...prevState, pageTransitioned: true };
      case 'pageTransitionMaskBackground':
        return { ...prevState, pageTransitionMaskBackground: action.payload };
      case 'pageTransitionMaskRef':
        return { ...prevState, pageTransitionMaskRef: action.payload };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
