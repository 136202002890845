import tinycolor from 'tinycolor2';
import has from 'lodash/has';
import { colors } from './theme';

export function getComplimentaryColor(targetColor) {
  const color = tinycolor(targetColor);
  return color.isDark() ? colors.white : colors.black;
}

export function getPageHeaderColor(pageId, data) {
  if (has(data, 'page.color.hex')) {
    return getComplimentaryColor(data.page.color.hex);
  }
  if (pageId === 'about') return colors.navy;
  if (pageId === 'services') return colors.darkGreen;
  if (pageId === 'contact') return colors.navy;
  if (pageId === 'gallery') return colors.darkMaroon;
  return colors.white;
}

export function getHeroColors(colorScheme) {
  switch (colorScheme) {
    case 'green':
      return {
        backgroundColor: colors.lightGreen,
        color: colors.darkGreen,
      };

    case 'maroon':
      return {
        backgroundColor: colors.lightMaroon,
        color: colors.darkMaroon,
      };

    case 'blue':
    default:
      return {
        backgroundColor: colors.offWhite,
        color: colors.navy,
      };
  }
}
