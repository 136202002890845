import { css } from 'styled-components';
import { easeInOut, cubicInOut } from './easings';
import { minWidth, maxWidth } from './breakpoints';

export const duration = 0.8;
export const stagger = duration / 4;

export const fadeInRight = (active, order = 1, delay = 0) => css`
  opacity: ${active ? 1 : 0};
  ${maxWidth('tabletPortrait')} {
    transform: translateX(${active ? 0 : 20}px);
  }
  ${minWidth('tabletPortrait')} {
    transform: translateX(${active ? 0 : 40}px);
  }
  transform-origin: right;
  transition: all ${duration}s ${easeInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  transition-property: opacity, transform;
`;

export const fadeInUp = (active, order = 1, delay = 0) => css`
  opacity: ${active ? 1 : 0};
  ${maxWidth('tabletPortrait')} {
    transform: translateY(${active ? 0 : 20}px);
  }
  ${minWidth('tabletPortrait')} {
    transform: translateY(${active ? 0 : 40}px);
  }
  transition: all ${duration}s ${easeInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  transition-property: opacity, transform;
`;

export const fadeInDown = (active, order = 1, delay = 0) => css`
  opacity: ${active ? 1 : 0};
  ${maxWidth('tabletPortrait')} {
    transform: translateY(${active ? 0 : -20}px);
  }
  ${minWidth('tabletPortrait')} {
    transform: translateY(${active ? 0 : -40}px);
  }
  transition: all ${duration}s ${easeInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  transition-property: opacity, transform;
`;

export const fadeIn = (active, order = 1, delay = 0) => css`
  opacity: ${active ? 1 : 0};
  transition: opacity ${duration}s ${easeInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
`;

export const unclipDown = (active, order = 1, delay = 0) => css`
  transition: -webkit-clip-path ${duration}s ${cubicInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  transition: clip-path ${duration}s ${cubicInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  clip-path: polygon(
    ${active ? `0 0, 100% 0, 100% 100%, 0% 100%` : `0 0, 100% 0, 100% 0, 0 0`}
  );
  img,
  div {
    will-change: transform;
    transition: transform ${duration}s ${cubicInOut}
      ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s !important;
    transform: scale(${active ? 1 : 1.1});
  }
`;

export const unclipLeft = (active, order = 1, delay = 0) => css`
  transition: clip-path ${duration}s ${cubicInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  clip-path: polygon(
    ${active ? `0 0, 100% 0, 100% 100%, 0% 100%` : `0 0, 0 0, 0 100%, 0% 100%`}
  );
  img,
  div {
    will-change: transform;
    transition: transform ${duration}s ${cubicInOut}
      ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s !important;
    transform: scale(${active ? 1 : 1.1});
  }
`;

export const unclipRight = (active, order = 1, delay = 0) => css`
  transition: clip-path ${duration}s ${cubicInOut}
    ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s;
  clip-path: polygon(
    ${active
      ? `0 0, 100% 0, 100% 100%, 0% 100%`
      : `100% 0, 100% 0, 100% 100%, 100% 100%`}
  );
  img,
  div {
    will-change: transform;
    transition: transform ${duration}s ${cubicInOut}
      ${((order - 1) * (active ? stagger + delay : 0)).toFixed(1)}s !important;
    transform: scale(${active ? 1 : 1.1});
  }
`;
