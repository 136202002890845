import React, { useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
import { store } from '../../context/store';

const Mask = styled.div`
  position: fixed;
  background: ${({ background }) => background};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transform: translateY(100%);
`;

function PageTransitionMask() {
  const { state, dispatch } = useContext(store);
  const [ref, setRef] = useState();

  useEffect(() => {
    dispatch({
      type: 'pageTransitionMaskRef',
      payload: ref,
    });
  }, [ref, dispatch]);

  return (
    <TransitionPortal>
      <Mask
        ref={(newRef) => setRef(newRef)}
        background={state.pageTransitionMaskBackground}
      />
    </TransitionPortal>
  );
}

export default withTheme(PageTransitionMask);
