import { useEffect, useState } from 'react';
import { breakpoints } from '../utils/theme';
import useWindowSize from './useWindowSize';

export default function useBreakpoint(breakpoint) {
  const [isBreakpoint, setIsBreakpoint] = useState();
  const windowSize = useWindowSize();

  useEffect(() => {
    const { matches } = window.matchMedia(
      `(min-width: ${breakpoints[breakpoint]}px)`
    );
    setIsBreakpoint(matches);
  }, [windowSize.width, breakpoint]);

  return isBreakpoint;
}
